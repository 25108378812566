import { useContext } from "react";
import SettingsContext from "../lib/settings";

const UnitEdit = () => {
  const { settings, setSettings } = useContext(SettingsContext);

  return (
    <div className="content">
      <h1 className="title">Preferred Units</h1>
      <div className="control">
        <div className="select">
          <select
            onChange={(e) =>
              setSettings({
                ...settings,
                ...{ unit: e.target.value as "metric" | "imperial" },
              })
            }
            value={settings.unit as "metric" | "imperial"}
          >
            <option value="imperial">Imperial (lb)</option>
            <option value="metric">Metric (kg)</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default UnitEdit;
