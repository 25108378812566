/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const navigate = useNavigate();

  const goto = (path: string, params: any = {}) => {
    setHamburgerOpen(false);
    navigate(path, params);
  };

  return (
    <nav
      className="navbar is-info"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <a className="navbar-item" href="/">
          <h1 className="title has-text-white">Barbell Toolkit</h1>
        </a>

        <a
          role="button"
          className={`navbar-burger ${hamburgerOpen ? "is-active" : ""}`}
          aria-label="menu"
          aria-expanded="false"
          onClick={() => setHamburgerOpen(!hamburgerOpen)}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div className={`navbar-menu ${hamburgerOpen ? "is-active" : ""}`}>
        <div className="navbar-end">
          <a className="navbar-item" onClick={() => goto("/")}>
            Plate Calculator
          </a>
          <a className="navbar-item" onClick={() => goto("/settings")}>
            Settings
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
