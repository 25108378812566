import { useContext, useState } from "react";
import SettingsContext, { Barbell } from "../lib/settings";

const BarbellEdit = () => {
  const { settings, setSettings } = useContext(SettingsContext);
  const [newBarbell, updateNewBarbell] = useState(["", 0, 0]);

  const saveBarbell = () => {
    const barbells = [...settings.barbells, newBarbell];
    setSettings(Object.assign({}, settings, { barbells }));
    updateNewBarbell(["", 0, 0]);
  };

  const deleteBarbell = (index: number) => {
    const barbells = [...settings.barbells];
    barbells.splice(index, 1);
    setSettings(Object.assign({}, settings, { barbells }));
  };

  return (
    <div className="content">
      <h1 className="title">Barbell Customization</h1>
      <p>Customize the barbells for the plate calculator.</p>
      <table>
        <thead>
          <tr>
            <th>Barbell Name</th>
            <th>Weight Lb</th>
            <th>Weight Kg</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {settings.barbells.map((barbell, index) => (
            <tr key={barbell[0]}>
              <td>{barbell[0]}</td>
              <td>{barbell[1]}</td>
              <td>{barbell[2]}</td>
              <td>
                <button
                  className="button is-danger is-small"
                  onClick={() => {
                    deleteBarbell(index);
                  }}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
          <tr>
            <td>
              <input
                className="input is-small"
                type="text"
                placeholder="Barbell"
                value={newBarbell[0]}
                onChange={(e) => {
                  const barbellCopy = [...newBarbell];
                  barbellCopy[0] = e.target.value;
                  updateNewBarbell(barbellCopy);
                }}
              />
            </td>
            <td>
              <input
                className="input is-small"
                type="number"
                placeholder="45"
                title="Weight Lb"
                value={newBarbell[1]}
                onChange={(e) => {
                  const barbellCopy = [...newBarbell];
                  barbellCopy[1] = e.target.value;
                  updateNewBarbell(barbellCopy);
                }}
              />
            </td>
            <td>
              <input
                className="input is-small"
                type="number"
                placeholder="20"
                title="Weight Kg"
                value={newBarbell[2]}
                onChange={(e) => {
                  const barbellCopy = [...newBarbell];
                  barbellCopy[2] = e.target.value;
                  updateNewBarbell(barbellCopy);
                }}
              />
            </td>
            <td>
              <button
                className="button is-info is-small"
                onClick={() => saveBarbell()}
              >
                Add
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BarbellEdit;
