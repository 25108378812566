import React from "react";
import { Plate } from "../lib/plates";

export type Barbell = [string, number, number];
export interface Settings {
  unit: "metric" | "imperial";
  barbells: Barbell[];
  plates: Plate[];
}

export const DEFAULT_BARBELLS: Barbell[] = [
  ["Generic Barbell 45lb/20kg", 45, 20],
  ["Generic Barbell 60lb/27kg", 60, 27],
  ["Generic Barbell 35lb/15kg", 35, 15],
  ["Generic Barbell 35lb/15kg", 30, 13],
  ["No Bar", 0, 0],
];

export const DEFAULT_SETTINGS: Settings = {
  unit: "imperial",
  barbells: [...DEFAULT_BARBELLS],
  plates: [],
};

export const SETTINGS_KEY = "settings";

export const getSettingsOrDefault = () => {
  const storedSettings = localStorage.getItem(SETTINGS_KEY);
  const settings = Object.assign(
    DEFAULT_SETTINGS,
    JSON.parse(storedSettings || "{}")
  );
  return settings;
};

const SettingsContext = React.createContext({
  settings: {} as Settings,
  setSettings: (settings: Settings) => {},
});

export default SettingsContext;
