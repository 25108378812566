// Stolen from https://github.com/queckezz/weight-plate-calculator/blob/master/lib/plates.js

export type Plate = [number, number];

export const DEFAULT_PLATES = {
  metric: [25, 20, 15, 10, 5, 2.5, 1.25],
  imperial: [45, 35, 25, 10, 5, 2.5, 1.25],
};

export function createUnlimitedPlateWeights(
  unit: "metric" | "imperial"
): Plate[] {
  return DEFAULT_PLATES[unit].map((n) => [n, 100]);
}

export function getPlates(
  initialWeight: number,
  {
    sides = 1,
    stack = null,
    equipmentWeight = 0,
    unit = "imperial",
  }: {
    sides?: number;
    stack?: Plate[] | null;
    equipmentWeight?: number;
    unit?: "metric" | "imperial";
  }
): {
  plates: Plate[];
  rest: number;
} {
  let plates: Plate[] = [];
  let remaining: number = initialWeight - equipmentWeight;

  stack = stack || createUnlimitedPlateWeights(unit);
  for (let [plateWeight, platesAvailable] of stack) {
    let plateCount = Math.floor(remaining / plateWeight);

    while (plateCount % sides !== 0) {
      plateCount--;
    }

    if (plateCount >= 1 && platesAvailable >= plateCount) {
      const totalWeight = plateCount * plateWeight;
      remaining = remaining - totalWeight;
      plates.push([plateWeight, plateCount / sides]);
    } else {
      plates.push([plateWeight, 0]);
    }
  }

  const rest: number = Math.floor(remaining * 100) / 100;

  return { plates, rest: rest > 0 ? rest : 0 };
}

export function hasZeroPlates(plates: Plate[]): boolean {
  return plates.every((n) => n[1] === 0);
}

export function tooLittleWeight(plates: Plate[], rest: number) {
  return hasZeroPlates(plates) && rest > 0;
}
