import BarbellEdit from "./BarbellEdit";
import PlateEdit from "./PlateEdit";
import UnitEdit from "./UnitEdit";

const Settings = () => {
  return (
    <section className="section">
      <h1 className="title">Settings</h1>
      <h2 className="subtitle">All settings are saved in your browser.</h2>
      <UnitEdit />
      <BarbellEdit />
      <PlateEdit />
    </section>
  );
};

export default Settings;
