import { useState, useEffect, useContext } from "react";
import SettingsContext, { DEFAULT_BARBELLS } from "../lib/settings";
import { getPlates } from "../lib/plates";

const UNIT_MEASUREMENT = {
  imperial: "lb",
  metric: "kg",
};

function Calculator() {
  const { settings } = useContext(SettingsContext);
  const [barbellWeight, setBarbellWeight] = useState<number | "">(
    settings.unit === "imperial" ? 45 : 20
  );
  const [targetWeight, setTargetWeight] = useState<number | "">(
    settings.unit === "imperial" ? 135 : 60
  );
  const [plates, setPlates] = useState([] as number[][]);
  const [rest, setRest] = useState(0);

  useEffect(() => {
    function calculateWeightPlates(
      weight = targetWeight,
      barbell = barbellWeight
    ) {
      if (weight === "" || barbell === "") {
        return;
      }
      const plates = getPlates(weight, {
        sides: 2,
        equipmentWeight: barbell,
        unit: settings.unit,
      });

      setPlates([...plates.plates]);
      setRest(plates.rest);
    }
    calculateWeightPlates(targetWeight, barbellWeight);
  }, [barbellWeight, targetWeight, settings.unit]);

  function calculateTotalWeight() {
    return (
      plates.reduce((prev, cur) => prev + Number(cur[0]) * Number(cur[1]), 0) *
        2 +
      Number(barbellWeight)
    );
  }

  return (
    <section className="section">
      <h1 className="title">Weight Plate Calculator</h1>
      <h2 className="subtitle">
        Calculate what plates to use for your barbell
      </h2>
      <label className="label">Barbell</label>
      <div className="field has-addons">
        <div className="control is-expanded">
          <div className="select is-fullwidth">
            <select onChange={(e) => setBarbellWeight(Number(e.target.value))}>
              {(settings.barbells || DEFAULT_BARBELLS).map(([name, weight]) => (
                <option key={name} value={weight}>
                  {name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <p className="control">
          <input
            className="input"
            type="number"
            placeholder={settings.unit === "imperial" ? "45" : "20"}
            value={barbellWeight}
            onChange={(e) => setBarbellWeight(Number(e.target.value) || "")}
          />
        </p>
      </div>

      <div className="field">
        <label className="label">Bar Weight</label>
        <div className="control">
          <input
            className="input"
            type="number"
            placeholder="135"
            value={targetWeight}
            onChange={(e) => setTargetWeight(Number(e.target.value) || "")}
          />
        </div>
      </div>

      <table className="table is-fullwidth is-striped is-bordered">
        <thead>
          <tr>
            <th>Plate</th>
            <th>Plates Per Side</th>
          </tr>
        </thead>
        <tbody>
          {plates.map(([plate, num]) => (
            <tr key={plate}>
              <td>
                <strong>
                  {plate} {UNIT_MEASUREMENT[settings.unit]}
                </strong>
              </td>
              <td>{num || ""}</td>
            </tr>
          ))}
          {rest > 0 && (
            <tr className="is-danger" style={{ color: "red" }}>
              <td>
                <strong style={{ color: "red" }}>Leftover</strong>
              </td>
              <td>
                {rest / 2} {UNIT_MEASUREMENT[settings.unit]}
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <table className="table is-fullwidth is-striped is-bordered">
        <tbody>
          <tr>
            <td>Total plate weight per side</td>
            <td>
              {plates.reduce((prev, cur) => prev + cur[0] * cur[1], 0)}{" "}
              {UNIT_MEASUREMENT[settings.unit]}
            </td>
          </tr>
          <tr>
            <td>Total plate weight on bar</td>
            <td>
              {plates.reduce((prev, cur) => prev + cur[0] * cur[1], 0) * 2}{" "}
              {UNIT_MEASUREMENT[settings.unit]}
            </td>
          </tr>
          <tr>
            <td>Barbell weight</td>
            <td>
              {barbellWeight} {UNIT_MEASUREMENT[settings.unit]}
            </td>
          </tr>
          <tr>
            <td>
              <strong>Total</strong>
            </td>
            <td>
              <strong
                className={
                  calculateTotalWeight() === targetWeight
                    ? "has-text-success"
                    : "has-text-danger"
                }
              >
                {calculateTotalWeight()} {UNIT_MEASUREMENT[settings.unit]}
              </strong>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
}

export default Calculator;
