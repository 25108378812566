import { useContext, useState } from "react";
import SettingsContext from "../lib/settings";
import { createUnlimitedPlateWeights, Plate } from "../lib/plates";

const PlateEdit = () => {
  const { settings, setSettings } = useContext(SettingsContext);
  const [newPlate, updateNewPlate] = useState([0, 0]);

  const plates: Plate[] =
    settings.plates.length > 0
      ? settings.plates
      : createUnlimitedPlateWeights(settings.unit);

  const savePlate = () => {
    for (let plate of plates) {
      if (plate[0] === newPlate[0]) {
        alert("Plate already exists");
        return;
      }
    }

    const updatedPlates = [...plates, newPlate];
    updatedPlates.sort((a, b) => b[0] - a[0]);
    setSettings(Object.assign({}, settings, { plates: updatedPlates }));
    updateNewPlate([0, 0]);
  };

  const deletePlate = (index: number) => {
    const updatedPlates = [...plates];
    updatedPlates.splice(index, 1);
    setSettings(Object.assign({}, settings, { plates: updatedPlates }));
  };

  const updatePlate = (index: number, newValue: number) => {
    const updatedPlates = [...plates];
    updatedPlates[index] = [updatedPlates[index][0], newValue];
    setSettings(Object.assign({}, settings, { plates: updatedPlates }));
  };

  return (
    <div className="content">
      <h1 className="title">Weight Rack</h1>
      <p>Customize the weights available for the plate calculator.</p>
      <table>
        <thead>
          <tr>
            <th>Plate</th>
            <th>Number Plates</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {plates.map((plate, index) => (
            <tr key={plate[0]}>
              <td>{plate[0]}</td>
              <td>
                <input
                  type="number"
                  value={plate[1]}
                  onChange={(e) => {
                    updatePlate(index, parseInt(e.target.value));
                  }}
                />
              </td>
              <td>
                <button
                  className="button is-danger is-small"
                  onClick={() => {
                    deletePlate(index);
                  }}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
          <tr>
            <td>
              <input
                className="input is-small"
                type="number"
                placeholder="Barbell"
                value={newPlate[0]}
                onChange={(e) => {
                  const plateCopy = [...newPlate];
                  plateCopy[0] = Number(e.target.value);
                  updateNewPlate(plateCopy);
                }}
              />
            </td>
            <td>
              <input
                className="input is-small"
                type="number"
                placeholder="45"
                title="Weight Lb"
                value={newPlate[1]}
                onChange={(e) => {
                  const plateCopy = [...newPlate];
                  plateCopy[1] = Number(e.target.value);
                  updateNewPlate(plateCopy);
                }}
              />
            </td>

            <td>
              <button
                className="button is-info is-small"
                onClick={() => savePlate()}
              >
                Add
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PlateEdit;
