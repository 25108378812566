import { Routes, Route } from "react-router-dom";
import { useState } from "react";
import SettingsContext, { getSettingsOrDefault } from "./lib/settings";
import Navbar from "./navbar/Navbar";
import Calculator from "./calculator/Calculator";
import Settings from "./settings/SettingsPage";
import { SETTINGS_KEY } from "./lib/settings";
import { useEffect } from "react";

function App() {
  const [settings, setSettings] = useState(getSettingsOrDefault());

  useEffect(() => {
    localStorage.setItem(SETTINGS_KEY, JSON.stringify(settings));
  }, [settings]);

  return (
    <SettingsContext.Provider
      value={{
        settings,
        setSettings,
      }}
    >
      <Navbar />

      <Routes>
        <Route path="/" element={<Calculator />} />
        <Route path="/settings" element={<Settings />} />
      </Routes>
    </SettingsContext.Provider>
  );
}

export default App;
